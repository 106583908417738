import UserRoles from '~/constants/user-roles.ts'
import { PageContext } from '~/renderer/types.ts'
import validateAccess from '~/utils/access-validator.ts'
import validateSession from '~/utils/session-validator.ts'

const allowedRoles = [UserRoles.TUTOR, UserRoles.GUARDIAN]

export const guard = (pageContext: PageContext) => {
  const { session } = pageContext
  validateSession(session)
  validateAccess(session, allowedRoles)
}
