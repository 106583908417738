import { Session } from 'next-auth'
import { redirect, render } from 'vike/abort'

export const validateNoSession = (session: Session) => {
  if (session.user) {
    throw redirect('/')
  }
}

const validateSession = (session: Session) => {
  if (!session.user) {
    throw render('/auth/login')
  }
}

export default validateSession
