import { redirect } from 'vike/abort'

import UserRoles from '~/constants/user-roles.ts'
import { PageContext } from '~/renderer/types.ts'
import validateAccess from '~/utils/access-validator.ts'
import validateSession from '~/utils/session-validator.ts'

const allowedRoles = [UserRoles.TUTOR]

export const guard = (pageContext: PageContext) => {
  const { session, routeParams } = pageContext
  validateSession(session)
  validateAccess(session, allowedRoles)

  if (!routeParams?.id) {
    throw redirect('/users')
  }
}
