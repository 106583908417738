import { Session } from 'next-auth'
import { redirect } from 'vike/abort'

import UserRoles from '~/constants/user-roles.ts'

const adminRoles = [UserRoles.ADMINISTRATOR, UserRoles.MODERATOR]

const validateAccess = (session: Session, allowedRoles?: UserRoles[]) => {
  if (
    !adminRoles.includes(session.user.role) &&
    !allowedRoles?.includes(session.user.role)
  ) {
    throw redirect('/')
  }
}

export default validateAccess
